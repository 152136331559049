import { AxiosError } from 'axios'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Chevron from '../Chevron'
import Alignment from '../Alignment'
import ConsultTableTypeCell from './ConsultTableTypeCell'
import { assignConsult } from 'services/consultsApi'
import { AddressType, CustomerType } from '../../types/customerType'
import {
  ConsultActionRequiredEnum,
  ConsultScopesEnum,
  ConsultStatusEnum,
  ConsultType,
  ConsultTypeEnum,
  EscalationsScope,
  FinishedStatuses,
} from '../../types/consultType'
import { SortOrderDirection } from '../../types/genericTypes'
import { differenceInDays } from 'date-fns'
import Button from '../Button'
import Modal from '../Modals/Modal'
import { NextRouter, useRouter } from 'next/router'
import { DateFormatEnum, formatDate, TimeFormatEnum } from '../../utils/dateTime'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { TableCell as MuiTableCell } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import theme from '../../styles/theme'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const useStyles = makeStyles(() =>
  createStyles({
    status: {
      fontWeight: 600,
      '& .MuiButtonBase-root': {
        marginLeft: '24px',
      },
    },
    table: {
      width: '100%',
      background: theme.colors.white,
      borderSpacing: 0,
    },
    header: {
      fontWeight: 600,
    },
    tableRow: {
      cursor: 'pointer',
      '& .MuiTableCell-root': {
        fontSize: '15px',
      },
    },
    loading: {
      color: theme.colors.primaryGreen,
    },
  }),
)
export type TableColumnType = {
  text: string
  key: string
  formatter?: (data: any, status: ConsultStatusEnum) => string | React.ReactElement
  nestedPath?: (data: any) => Record<string, unknown>
  isSortable?: boolean
}

interface ConsultsTableProps {
  page: number
  pageSize: number
  data: Array<ConsultType>
  totalCount: number
  isLoading: boolean
  scope: string
  consultRefreshRate: number
  getConsults: ({
    page,
    sortDirection,
    sortBy,
  }: {
    page?: number
    sortDirection: SortOrderDirection
    sortBy: string
  }) => void
}

const Container = styled.div`
  padding-right: 10px;
  font-size: 15px;
`

export const EnhancedTableHead = ({
  sortDirection,
  columns,
  sortBy,
  handleSortRequest,
  classes,
}: {
  sortDirection: SortOrderDirection
  columns: Array<TableColumnType>
  sortBy: string
  handleSortRequest: (key: string) => void
  classes?: ClassNameMap<'table' | 'status' | 'header' | 'tableRow' | 'loading'>
}): JSX.Element => {
  const sortDir = sortDirection === SortOrderDirection.ASC ? 'asc' : 'desc'
  return (
    <TableHead>
      <TableRow>
        {columns.map(headCell => (
          <MuiTableCell
            key={headCell.text}
            align={headCell.key === 'lastName' ? 'left' : 'center'}
            padding={'normal'}
            onClick={() => headCell.isSortable && handleSortRequest(headCell.key)}
            className={headCell.key === 'status' ? classes?.status : classes?.header}
          >
            {headCell.isSortable ? (
              <TableSortLabel active={sortBy === headCell.key} direction={sortDir}>
                {headCell.text}
              </TableSortLabel>
            ) : (
              headCell.text
            )}
          </MuiTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export const TableCell = (props: {
  text: string
  data: any
  formatter?: any
  status?: ConsultStatusEnum
  priority?: number
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined
}): JSX.Element => {
  const { data, formatter, status, text, align } = props
  // Styles specific to Consult Type cell
  if (text === 'Consult Type') {
    return (
      <ConsultTableTypeCell type={data as ConsultTypeEnum} priority={props.priority}>
        {formatter ? formatter(data, status) : data}
      </ConsultTableTypeCell>
    )
  }
  const cellAlign = align || 'center'
  return <MuiTableCell align={cellAlign}>{formatter ? formatter(data, status) : data}</MuiTableCell>
}

export const EnhancedTableContent = ({
  consultData,
  columns,
  classes,
  router,
  setIsLoading,
  patientEscalations,
  handleError,
}: {
  consultData: Array<ConsultType>
  columns: Array<TableColumnType>
  setIsLoading?: (val: boolean) => void
  router?: NextRouter
  patientEscalations: boolean
  handleError: (reason: Error) => void
  classes?: ClassNameMap<'table' | 'status' | 'header' | 'tableRow' | 'loading'>
}): JSX.Element => {
  return (
    <TableBody>
      {consultData.map((row: ConsultType) => {
        return (
          <TableRow
            className={classes?.tableRow}
            hover
            key={row.id}
            onClick={() => {
              setIsLoading && setIsLoading(true)
              if (!patientEscalations) {
                router && router.push(`/consult/${row.id}`)
              } else {
                assignConsult({ consultId: row.id })
                  .then(() => {
                    router && router.push(`/consult/${row.id}`)
                  })
                  .catch(handleError)
              }
            }}
          >
            {columns.map((obj: TableColumnType) => {
              if (obj['nestedPath']) {
                const path = obj.nestedPath(row)
                return (
                  <TableCell
                    align={obj.key === 'lastName' ? 'left' : 'center'}
                    {...obj}
                    formatter={obj?.formatter}
                    status={row.status}
                    priority={row.priority}
                    data={path}
                    key={obj.key}
                  />
                )
              }
              return (
                <TableCell
                  align={obj.key === 'lastName' ? 'left' : 'center'}
                  {...obj}
                  formatter={obj?.formatter}
                  status={row.status}
                  priority={row.priority}
                  data={get(row, obj.key)}
                  key={obj.key}
                />
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

const actionCellFormatter = (data: string) => (
  <div
    style={{
      color: theme.colors.white,
      backgroundColor:
        data == ConsultActionRequiredEnum.Reply
          ? theme.colors.midBlue
          : data == ConsultActionRequiredEnum.LabsReview
          ? theme.colors.lightPurple
          : theme.colors.white,
      borderRadius: 4,
      padding: 5,
    }}
  >
    {data == ConsultActionRequiredEnum.Reply
      ? 'Reply'
      : data == ConsultActionRequiredEnum.LabsReview
      ? 'Review Labs'
      : ''}
  </div>
)
const CONSULT_SORTING_SETTING = `consultSortingOrder`
export const getSortingSetting = (scope: string) => {
  const sortString = localStorage.getItem(CONSULT_SORTING_SETTING)
  const sortSetting = sortString ? JSON.parse(sortString) : {}
  const currentScopeSortSetting = sortSetting[scope || ConsultScopesEnum.Open]
  return currentScopeSortSetting
}

const ConsultsTable = (props: ConsultsTableProps) => {
  const synchronous =
    props.scope == ConsultScopesEnum.Synchronous ||
    props.scope == ConsultScopesEnum.SynchronousNeedsAttention
  const patientEscalations = props.scope == EscalationsScope
  const router = useRouter()
  const classes = useStyles()
  const [page, setPage] = useState(props.page)
  const [sortBy, setSortBy] = useState(synchronous ? 'scheduledAt' : 'createdAt')
  const [sortDirection, setSortOrder] = useState(SortOrderDirection.ASC)
  const [isLoading, setIsLoading] = useState(props.isLoading)
  const [error, setError] = useState<string | undefined>()
  useEffect(() => {
    const currentScopeSortSetting = getSortingSetting(props.scope)
    if (currentScopeSortSetting) {
      setSortBy(currentScopeSortSetting.sortBy)
      setSortOrder(currentScopeSortSetting.sortDirection)
      props.getConsults(currentScopeSortSetting)
    }
  }, [props.scope])
  useEffect(() => {
    const refreshConsults = async () => {
      if (document.hidden) {
        return
      }
      const scopeSortSetting = getSortingSetting(props.scope)
      if (scopeSortSetting) {
        setSortBy(scopeSortSetting.sortBy)
        setSortOrder(scopeSortSetting.sortDirection)
        await props.getConsults({ page, ...scopeSortSetting })
      } else {
        await props.getConsults({ page, sortBy, sortDirection })
      }
    }

    const refreshInterval = window.setInterval(refreshConsults, props.consultRefreshRate)
    window.addEventListener('visibilitychange', refreshConsults)
    return () => {
      window.removeEventListener('visibilitychange', refreshConsults)
      window.clearInterval(refreshInterval)
    }
  }, [props.getConsults])
  const columns: Array<TableColumnType> = [
    {
      text: 'Patient Name',
      key: 'lastName',
      nestedPath: (data: { customer: CustomerType }): CustomerType => {
        return data.customer
      },
      formatter: (data: any) => {
        return `${data.lastName}, ${data.firstName}`
      },
      isSortable: true,
    },
    {
      text: 'Consult Type',
      key: 'type',
      isSortable: true,
    },
    ...(props.scope == ConsultScopesEnum.SynchronousNeedsAttention
      ? [
          {
            text: 'Action required',
            key: 'actionRequired',
            isSortable: false,
            formatter: actionCellFormatter,
          },
        ]
      : []),
    !patientEscalations
      ? {
          text: 'Status',
          key: 'status',
        }
      : {
          text: 'Patient State',
          key: 'state',
          isSortable: false,
          nestedPath: (data: { customer: CustomerType }) => data.customer.currentAddress,
          formatter: (data: AddressType) => data?.state || '',
        },
    {
      text: 'Last Activity',
      key: 'updatedAt',
      isSortable: true,
      formatter: (data: string) => {
        return formatDate(
          data,
          `${DateFormatEnum.MonthDayFullYear} ${TimeFormatEnum.HourMinutePeriod}`,
        )
      },
    },
    !synchronous
      ? {
          text: 'Consult Length',
          key: 'createdAt',
          isSortable: true,
          formatter: (data: string, status: ConsultStatusEnum) => {
            // Do not display length for finished consults
            if (FinishedStatuses.includes(status)) {
              return '-'
            }
            const createdAt = new Date(data)
            const today = new Date()
            const difference = differenceInDays(today, createdAt)
            return difference > 1 ? `${difference} days` : `< 1 day`
          },
        }
      : {
          text: 'Starting',
          key: 'scheduledAt',
          isSortable: true,
          formatter: (data: string) => {
            return `${formatDate(data, DateFormatEnum.MonthDayFullYear)} (${formatDate(
              data,
              TimeFormatEnum.HourMinutePeriod,
            )})`
          },
        },
  ]

  const handleSortRequest = (key: string) => {
    setPage(0)
    let newSortOrder = SortOrderDirection.ASC
    if (sortBy === key && sortDirection === SortOrderDirection.DESC) {
      setSortOrder(SortOrderDirection.ASC)
    } else if (sortBy !== key) {
      setSortBy(key)
      setSortOrder(SortOrderDirection.ASC)
    } else if (sortBy === key) {
      newSortOrder = SortOrderDirection.DESC
      setSortOrder(SortOrderDirection.DESC)
    }
    const sortString = localStorage.getItem(CONSULT_SORTING_SETTING)
    const sortingSet = sortString ? JSON.parse(sortString) : {}
    sortingSet[props.scope || ConsultScopesEnum.Open] = { sortDirection: newSortOrder, sortBy: key }
    localStorage.setItem(CONSULT_SORTING_SETTING, JSON.stringify(sortingSet))
    props.getConsults({ sortDirection: newSortOrder, sortBy: key })
  }

  const handleSelectionError = (reason: Error) => {
    setIsLoading(true)
    props.getConsults({ sortDirection, sortBy, page })
    setIsLoading(false)
    if (!(reason as unknown as Record<string, unknown>).isAxiosError) {
      setError(reason.message)
    } else {
      const error = reason as AxiosError<{ error: string }>
      if (error.response?.status == 409) {
        setError('This consultaton was already claimed by a different provider')
      } else if (error.response?.status == 403) {
        setError('You do not have permission to claim PE consultations')
      } else {
        setError(error.response?.data?.error || 'An unspecified error has occurred.')
      }
    }
  }

  const TablePagination = ({
    page,
    pageSize,
    totalCount,
  }: {
    page: number
    pageSize: number
    totalCount: number
  }) => {
    const totalPages = Math.ceil(totalCount / pageSize)
    const secondNumber = pageSize * (page + 1)
    const currentlyDisplayed = `${pageSize * page + 1} - ${
      totalCount > secondNumber ? secondNumber : totalCount
    } of ${totalCount}`

    const handlePageChange = (newPage: number) => {
      props.getConsults({ page: newPage, sortBy, sortDirection })
      setPage(newPage)
    }
    return (
      <Alignment justifyContent="space-between" alignItems="center" padding="10px 0 0 0">
        <Alignment>
          <Container>Displaying: {currentlyDisplayed}</Container>
        </Alignment>
        <Alignment alignItems="center">
          <Button
            whiteText
            backgroundColor="primaryGreen"
            type="button"
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 0}
          >
            <Chevron height={24} rotate={180} />
          </Button>
          <Container>
            Page: {page + 1} - {totalPages}
          </Container>
          <Button
            whiteText
            backgroundColor="primaryGreen"
            type="button"
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages - 1}
          >
            <Chevron height={24} />
          </Button>
        </Alignment>
      </Alignment>
    )
  }

  return (
    <div>
      {!!error && (
        <Modal
          type="centered"
          title="An error occurred trying to reserve this consultation"
          close={() => {
            setError(undefined)
          }}
        >
          <p>{error}</p>
        </Modal>
      )}
      {!isLoading && (
        <>
          {props.data.length > 0 ? (
            <div>
              <Table className={classes.table}>
                <EnhancedTableHead
                  columns={columns}
                  sortDirection={sortDirection}
                  sortBy={sortBy}
                  handleSortRequest={handleSortRequest}
                  classes={classes}
                />
                <EnhancedTableContent
                  consultData={props.data}
                  columns={columns}
                  setIsLoading={setIsLoading}
                  router={router}
                  classes={classes}
                  patientEscalations={patientEscalations}
                  handleError={handleSelectionError}
                />
              </Table>
              <TablePagination
                totalCount={props.totalCount}
                page={page}
                pageSize={props.pageSize}
              />
            </div>
          ) : (
            <Alignment justifyContent="center">
              <span>No Consultations to Display</span>
            </Alignment>
          )}
        </>
      )}
      {isLoading && (
        <Alignment justifyContent="center" alignItems="center" height={'50vh'}>
          <CircularProgress className={classes.loading} />
        </Alignment>
      )}
    </div>
  )
}

export default ConsultsTable
